import * as React from "react"
import Layout from "../components/layout"
import {ContentIndex} from "../components/mainPage/ContentIndex";

const Index = ({ }) => {
  return (
    <Layout title={"Actualité politique"}>

      <ContentIndex />
    </Layout>
  )
}

export default Index
